import styled from "styled-components";
import { BannerBg } from "../../styled/AllImages";

export const BannerWrapper = styled.section`
  background-image: url(${BannerBg});
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-size: cover !important;
  background-position: center !important;
  display: flex;
  align-items: center;
  position: relative;

  .banner-content-wrapper {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    flex-wrap: wrap;
    .main-heading {
      line-height: 55px;
      margin-bottom: 10px;
    }
  }

  .banner-contact-btn {
    justify-content: center;
  }

  .banner-content {
    justify-content: ${(props) => (props.lang === "ar" ? "start" : "center")};
    flex-direction: ${(props) => (props.lang === "ar" ? "row-reverse" : "row")};
    /* flex-wrap: nowrap; */
  }

  .banner-heading {
    text-align: left;
    transform: rotate(-2.75deg);
    line-height: .9;
  }
  .banner-text {
    text-align: left;
    transform: rotate(-3.62deg);
    margin-bottom: 0;
    line-height: 1;
    font-family: var(--bold2);
    padding-left: 35px;
  }
  .banner-text2 {
    text-align: left;
    margin-bottom: .3rem;
    font-family: var(--bold2);
    transform: rotate(0deg);
    padding-left: 55px;
  }
  .banner-img {
    width: 100%;
    position: absolute;
    bottom: -220px;
    left: ${(props) => (props.lang === "ar" ? "-100px" : "-200px")};
    span {
    
      img {
        max-width: 700px;
        height: 100%;
        object-fit: cover;
      }
    }
  }
.down-btn{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 40px;
}
  
`;
