import React from "react";
import { BannerWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Box,
  HeadingStyled,
  LazyImage,
  MainHeading,
  SpanStyled,
  TextStyled,
} from "../../styled/Elements";
import { ContactButton } from "../../styled/CustomElements";
import {
  BannerImg,
  BannerImgAr,
  BannerImgArRes,
  BannerImgRes,
  DowmBtn,
  Instagram,
  LinkedIn,
  Twitter,
} from "../../styled/AllImages";

const Banner = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  // const currentLang = i18n.language;
  const WhatsApp = () => {
    const phoneNumber = "+966556202503";
    const message = "Hello, Super Active Baby  ";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const socialIcon = [
    {
      img: <Twitter />,
      // link: 'https://twitter.com/deyamlandscape',
    },
    {
      img: <Instagram />,
      // link: 'https://twitter.com/deyamlandscape',
    },
    {
      img: <LinkedIn />,
      // link: 'https://www.tiktok.com/@deyamlandscape',
    },
  ];
  return (
    <>
      <BannerWrapper id="home">
        <Container>
          <Row className="banner-content position-relative w-100">
            <Col lg={5} md={6} className="position-relative">
              <Box className="banner-content-wrapper">
                <MainHeading className="blueColor banner-heading" size="64px" >  {t("banner_heading")}</MainHeading>
                <MainHeading className={`orangeColor banner-text banner-subheading`} size="50px" transform='math-auto'> {t("banner_text")} </MainHeading>
                <MainHeading className={`greenColor banner-text2 banner-textheading`} size="50px" transform='math-auto'> {t("banner_text2")} </MainHeading>
                <SpanStyled className={`d-flex align-items-center banner-contact-btn w-100 gap-2`}>
                  <ContactButton label={t("contact_us_now")} onClick={WhatsApp} rotate />
                </SpanStyled>
              </Box>
            </Col>
            <Col lg={5} md={6} className="position-relative w-50">
              <Box className="banner-img">
                {isRTL ? <LazyImage src={BannerImgAr} className="BannerImgAr" /> : <LazyImage src={BannerImg} className="BannerImg" />}
                {isRTL ? <LazyImage src={BannerImgArRes} className="BannerImgArRes" /> : <LazyImage src={BannerImgRes} className="BannerImgRes" />}

              </Box>
            </Col>
          </Row>
          <Box className="social-link">
            {socialIcon.map((list, key) => (
              <Box key={key}>
                <a href={list.link} target="_blank" rel="noopener noreferrer">
                  {list.img}
                </a>
              </Box>
            ))}
          </Box>

        </Container>
        <Box className="down-btn">
          <a href="#about">
            <DowmBtn />
          </a>
        </Box>
      </BannerWrapper>
    </>
  );
};

export default Banner;
