import React from "react";
import { FooterWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import {
  Box,
  HeadingStyled,
  LazyImage,
  SpanStyled,
} from "../../styled/Elements";
import { NavDataConfig } from "../../../data/Data";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FooterLogo, ProductsImg } from "../../styled/AllImages";
import { LogoWrapper } from "../../styled/CustomElements";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language
  const navigate = useNavigate();
  const date_year = new Date();
  const FowlUrl = () => {
    const whatsappURL = "https://fowl.sa/home";
    window.open(whatsappURL, "_blank");
  };
  return (
    <FooterWrapper className="container-fluid" lang={currentLang}>
      <Container>
        <Row className="footer-row align-items-center">
          <Col lg={2} md={12} sm={12}>
            <Box className="f-logo-wrap">
              <LogoWrapper className={`logo`} onClick={() => navigate("/")}>
                <LazyImage src={FooterLogo} />
              </LogoWrapper>
            </Box>
          </Col>
          <Col lg={8} md={12} sm={12}>
            <Box className="d-flex flex-column justify-content-center align-items-center">
              <Box className="footer-list">
                {NavDataConfig.map((item, key) => (
                  <Box key={key} className="footer-nav-items d-flex gap-2">
                    <Link
                      to={item.to}
                      spy={true}
                      smooth={true}
                      offset={-60}
                      duration={500}
                      className="text-decoration-none"
                    >
                      {t(item.label)}
                    </Link>
                  </Box>
                ))}
              </Box>
              <Box className="text-center">
                <SpanStyled
                  className="footer-op"
                  size="13px"
                  color="var(--txtColor)"
                  transform="capitalize"
                >
                  {t("footer_text")}
                </SpanStyled>
                <HeadingStyled
                  className="footer-op"
                  size="13px"
                  color="var(--txtColor)"
                  transform="capitalize"
                >
                  {t("developed_by")}
                  <SpanStyled
                    className="fowl footer-op"
                    color="var(--txtColor)"
                    size="14px"
                    family="var(--semiBold)"
                    onClick={FowlUrl}
                  >
                    {t("fowl")}
                  </SpanStyled>
                </HeadingStyled>
              </Box>
            </Box>
          </Col>
          <Col lg={2} md={12} sm={12}>
            <Box className="d-flex align-items-center gap-3 footerSocial">
              <LazyImage src={ProductsImg} />
            </Box>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
