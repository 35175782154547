// import i18n from './Languages/INEXT';
import React, { useEffect } from 'react'
import { Box } from './components/styled/Elements';
import { Route, Routes, useLocation } from 'react-router-dom';
// import { useCookies } from 'react-cookie';
import { MainRoutes } from './routes/Routes';


function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: 'smooth' })
  }, [location]);
//   const [cookies, setCookie] = useCookies(['lang']);
//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//     setCookie('lang', lng, { path: '/' });
//   };

  return (
    <Box className='main-artical'>
      <Routes>
        {MainRoutes.map((list, key) => (
          <Route to={key} key={key} {...list} />
        ))}
      </Routes>
    </Box>
  );
}

export default App;
