import styled from "styled-components";
import { Box, HeadingStyled, SpanStyled } from "./Elements";
import {
  LeftLeafOrange,
  LeftLeafWhite,
  RightLeafOrange,
  RightLeafWhite,
} from "./AllImages";

export const sliceProps = (props, sub) => {
  const { children, ...otherProps } = props;
  sub.forEach((prop) => delete otherProps[prop]);
  return otherProps;
};
export const ButtonWithIcon = (props) => {
  const unwantedProps = ["icon", "label", "bgBtn", "clName", "clNameMain"];
  const buttonProps = props.type ? { href: props.type } : {};
  return (
    <ButtonWrapper
    {...sliceProps(props, unwantedProps)}
      className={`d-flex align-items-center ${props.clNameMain ? props.clNameMain : ""
        }`}
    >
      <button
        {...buttonProps} // Conditionally spread the href prop if it exists
        className={` ${props.clName ? props.clName : ""} ${props.bgBtn ? props.bgBtn : ""
          } ${!props.icon ? "Btn-icon" : ""} `}
          {...buttonProps} 
        autoComplete="false"
        onClick={() => {
          if (props.type) {
            window.location.href = props.type;
            
          }
        }}
      >
        {props.icon && <props.icon width="20px" />}
        <SpanStyled size="18px">{props.label ? props.label : ""}</SpanStyled>
      </button>
    </ButtonWrapper>
  );
};

export const ContactButton = (props) => {
  const unwantedProps = ["icon", "label"];
  return (
    <ContactWrapper {...sliceProps(props, unwantedProps)}>
      <button
        className={`btn yellow gap-3 ${!props.icon ? "Btn-icon" : ""}`}
        autoComplete="false"
      >
        <Box>
          <HeadingStyled color="var(--white)" size="20px" weight="300px">
            {props.label ? props.label : ""}
          </HeadingStyled>
          <span></span>
        </Box>
        {props.icon && <props.icon width="22px" />}
      </button>
    </ContactWrapper>
  );
};

export const ContactWrapper = styled.div`
  max-width: 175px;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) =>
    props.background ? props.background : "var(--gradient)"};
    border-radius: 18px;
    min-height: 45px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s linear;
    transform: rotate(${(props) => (props.rotate ? "-2.7deg" : "0")});
    z-index: 1;
    position: relative;
    padding:11px 25px;

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      /* background: linear-gradient(white, white) padding-box,
        linear-gradient(219.06deg, #b959e3 6.88%, #662782 77.05%) border-box; */
      pointer-events: none;
      z-index: -1;
      background-image: ${(props) =>
    props.white
      ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='gradient' x1='1' y1='0' x2='0' y2='0'%3e%3cstop offset='6.88%25' stop-color='%23FFAA06' /%3e%3cstop offset='77.05%25' stop-color='%23D85A1F' /%3e%3c/linearGradient%3e%3c/defs%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='50' stroke='url(%23gradient)' stroke-width='4' stroke-dasharray='6%2c 8' stroke-dashoffset='100' stroke-linecap='round'/%3e%3c/svg%3e")`
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='gradient' x1='1' y1='0' x2='0' y2='0'%3e%3cstop offset='6.88%25' stop-color='%23B959E3' /%3e%3cstop offset='77.05%25' stop-color='%23662782' /%3e%3c/linearGradient%3e%3c/defs%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='50' stroke='url(%23gradient)' stroke-width='4' stroke-dasharray='6%2c 8' stroke-dashoffset='100' stroke-linecap='round'/%3e%3c/svg%3e")`};

      border-radius: 18px;
    }

    span {
      position: relative;
      width: 100%;
      display: block;
      min-height: 1px;
      &::before,
      &::after {
        position: absolute;
        top: -42px;
        width: 50px;
        height: 50px;
        background-size: contain; /* Ensure the image fits within the box */
        background-repeat: no-repeat;
        cursor: default;
        pointer-events: none;
      }
      &::before {
        content: url(${(props) => props.white ? LeftLeafWhite : LeftLeafOrange});
        left: -70px;
      }
      &::after {
        content: url(${(props) => props.white ? RightLeafWhite : RightLeafOrange});
        right: -70px;
      }
    }
  }

  .ion-icon {
    font-size: 2em;
    transition: all 0.5s linear;
  }

  div {
    position: relative;
    &>div{
    white-space: nowrap;
    }
  }
`;

export const ButtonWrapper = styled.div`
  justify-content: ${(props) => (props.center ? "center" : "unset")};
  button {
    position: relative;
    border: none;
    padding: 1em;

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      /* background: linear-gradient(white, white) padding-box,
        linear-gradient(219.06deg, #b959e3 6.88%, #662782 77.05%) border-box; */
      pointer-events: none;
      z-index: -1;
      background-image: ${(props) =>
    props.orange
      ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='gradient' x1='1' y1='0' x2='0' y2='0'%3e%3cstop offset='6.88%25' stop-color='%23FFAA06' /%3e%3cstop offset='77.05%25' stop-color='%23D85A1F' /%3e%3c/linearGradient%3e%3c/defs%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='50' stroke='url(%23gradient)' stroke-width='4' stroke-dasharray='6%2c 8' stroke-dashoffset='100' stroke-linecap='round'/%3e%3c/svg%3e")`
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='gradient' x1='1' y1='0' x2='0' y2='0'%3e%3cstop offset='6.88%25' stop-color='%23B959E3' /%3e%3cstop offset='77.05%25' stop-color='%23662782' /%3e%3c/linearGradient%3e%3c/defs%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='50' stroke='url(%23gradient)' stroke-width='4' stroke-dasharray='6%2c 8' stroke-dashoffset='100' stroke-linecap='round'/%3e%3c/svg%3e")`};

      border-radius: 18px;
    }
    /* border-image-slice: 1; */

    height: 45px;
    /* border: none; */
    padding: 10px 18px;
    border-radius: 18px;
    transform: scale(0.97);
    transition: all 0.4s ease-in-out;
    align-items: center;
    & > span {
      color: var(--white);
      font-family: var(--regularAr);
    }
    /* &:hover {
      transform: scale(1);
    } */
  }
`;
export const LogoWrapper = styled.div``;
