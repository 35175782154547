import styled from "styled-components";

export const AboutWrapper = styled.section`
  width: 100%;
  height: 100%;
  // background-color: var(--white);
  .underline {
    position: relative;
    top: -4px;
  }
  .about-content {
    position: relative;
    width: 100%;
    height: 740px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .about-2-img{
    position: relative;
    text-align: center;

    &>span{
          width: 530px;
          position: absolute;
          bottom: -200px;
          left: -25px;
      &>img{
        width:100%;
        height:100%;
      }
    }
  }
  .about-text {
    text-align: left;
    position: relative;
  }
  .about-text-content{
    width: 100%;
    padding-left: 150px;
  }
  .l-height {
    line-height: 0.9;
  }
  .about-contact-btn {
    justify-content: left;
  }
  .about-rows {
    position: relative;
    padding-top: 55px;
    padding-bottom: 50px;
    position: relative;
    align-items: baseline;
    justify-content: center;
  }
  .about-rows::before {
    content: '';
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    /* bottom: 0; */
    margin: auto;
    height: 60%;
    width: 1px;
    border-right: 1px solid #969696;
  }
  .about-img {
    & > div {
      border-radius: 16px;
    }
  }
  .about-img-wrap-1 {
    border-radius: 16px;
    position: absolute;
    left: -50px;
    top: 90px;

    & img {
      border-radius: 16px;
    }
  }
  .about-img-wrap-2 {
    border-radius: 16px;
    position: absolute;
    right: -50px;
    bottom: 60px;
    & img {
      border-radius: 16px;
    }
  }

  .about2-content {
    position: relative;
    display: flex;
    align-items: center;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 151px 0;
  }
  
  .batch{
    position: absolute;
    top: -30px;
    left: 40px;
    width: 194px;
    height: 194px;
    text-align: center;
    padding:40px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: white;
  }
    .about-andHeading{
      position: absolute;
      display: flex;
      padding: 3px 6px 3px 6px;
      background-color: var(--orange);
      color: #fff;
      font-size: 16px;
      left: 153px;
      top: 50px;
    }
`;
