import styled from "styled-components";
import { BabyBear, Layers, Prod1, Prod2 } from "../../styled/AllImages";

export const FeaturesWrapper = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background:var(--featureGradient);

  .features-heading {
    line-height: 1;
  }
  .feature-container {
    width: 100%;
    height: 950px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .features-head::before,
  .features-head::after {
    position: absolute;
    top: 1.5rem;
    width: 170px;
    height: 250px;
   
    cursor: default;
    pointer-events: none;
    z-index: 9;
  }
  .features-head::before {
    content: '' ;
    left: -60px;
    background:url(${Prod1});
    background-size: cover; 
    background-repeat: no-repeat;
  }
  .features-head::after {
    content: '' ;
    right: -30px;
    background:url(${Prod2});
    background-size: cover; 
    background-repeat: no-repeat;
  }
  .feature-container::before,
  .feature-container::after {
    position: absolute;
    width: 260px;
    height: 250px;
    cursor: default;
    pointer-events: none;
    z-index: 9;
  }
  .feature-container::before {
    content: '' ;
    bottom: 150px;
    left: 20px;
    background:url(${BabyBear});
    background-size: cover; 
    background-repeat: no-repeat;
  }
  .feature-container::after {
    content: '';
    bottom: 150px;
    right: 10px;
    background:url(${Layers});
    background-size: cover; 
    background-repeat: no-repeat;
  }
  .feature-text {
    width: 70%;
  }

  /********* Features Image ********/
  .feature-img-container {
    height: 500px;
  }
  .diaper-img {
    max-width: 900px;
  }
  .feature-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 125px;
  }
  .feature-icons img {
    width: 110px;
  }
  .feature1 {
    top: 113px;
    right: -62px;
  }
  .feature2 {
    right: 43%;
    bottom: -155px;
  }
  .feature3 {
    top: 313px;
    left: 117px;
  }
  .feature4 {
    top: 113px;
    left: -62px;
  }
  .feature5 {
    top: 314px;
    right: 102px;
  }

  @media (max-width: 1150px) {
    .diaper-img {
      max-width: 700px;
    }
    .feature-icons img {
      width: 70px;
    }
    .feature1 {
      top: 90px;
      right: -62px;
    }
    .feature2 {
      right: 41.3%;
      bottom: -16px;
    }
    .feature3 {
      top: 252px;
      left: 82px;
    }
    .feature4 {
      top: 90px;
      left: -62px;
    }
    .feature5 {
      top: 252px;
      right: 70px;
    }
  }
  @media (max-width: 920px) {
    .diaper-img {
      max-width: 500px;
    }
    .feature-icons img {
      width: 60px;
    }
    .feature1 {
      top: 65px;
      right: -62px;
    }
    .feature2 {
      right: 38%;
      bottom: 110px;
    }
    .feature3 {
      top: 180px;
      left: 41px;
    }
    .feature4 {
      top: 65px;
      left: -62px;
    }
    .feature5 {
      top: 181px;
        right: 33px;
    }
  }
  @media (max-width: 690px) {
    .diaper-img {
      max-width: 300px;
    }
    .feature-name {
      font-size: 12px;
    }
    .feature-icons img {
      width: 40px;
    }
    .feature1 {
      top: 39px;
      right: -62px;
    }
    .feature2 {
      right: 29.3%;
        bottom: 251px;
    }
    .feature3 {
      top: 108px;
        left: -1px;
    }
    .feature4 {
      top: 39px;
      left: -62px;
    }
    .feature5 {
      top: 109px;
        right: -6px;
    }
  }
`;
