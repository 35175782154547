import React from 'react'
import Topbar from '../components/Common/topbar/Topbar'
import Banner from '../components/Sections/Banner/Banner'
import Footer from '../components/Common/footer/Footer'
import About from '../components/Sections/about/About'
import Features from '../components/Sections/features/Features'
import Suppliers from '../components/Sections/Suppliers/Suppliers'
import ContactUs from '../components/Sections/contactUs/ContactUs'
import Products from '../components/Sections/product/Products'

const Index = () => {
  return (
    <div>
        <Topbar/>
        <Banner />
        <About />
        <Features />
        <Products />
        <Suppliers />
        <ContactUs/>
        <Footer />
    </div>
  )
}

export default Index