import React from "react";
import { SuppliersWrapper } from "./Styled";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { Box, LazyImage, MainHeading, TextStyled } from "../../styled/Elements";
import {
  BabyFun,
  BabyShop,
  Cutes,
  ForMoms,
  Hibobi,
  Huggies,
  MamaPapa,
  SupplierBg,
} from "../../styled/AllImages";
import { supplyData } from "../../../data/Data";

const Suppliers = () => {
  const { t } = useTranslation();

  const WhatsApp = () => {
    const phoneNumber = "+966556202503";
    const message = "Hello, Super Active Baby ";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <SuppliersWrapper id="suppliers">
      <Container>
        <Box
          className="supplier-container"
          style={{ backgroundImage: `url(${SupplierBg})` }}
        >
          <Box className="pt-5 mt-5 px-lg-5 px-md-3">
            <MainHeading size="32px" className="blueColor supplier-head heading-blue" weight="bolder">
              {t('partnering_with_the_best')}
            </MainHeading>
            <MainHeading size="48px" className="orangeColor supplier-head heading-orange" weight="bolder">
              {t('trusted_suppliers')}
            </MainHeading>
            <TextStyled className="mt-4">
              {t('collaborate_with_top_tier_suppliers')}
            </TextStyled>
          </Box>
          <Row className="mt-5 pb-5 px-lg-3 px-md-2 suppliers-imgs">
            <Box className="supply-grid">
              {
                supplyData.map((list, index) => (
                  <Box key={index}>
                    {list.img}
                  </Box>
                ))
              }
            </Box>
          </Row>
        </Box>
      </Container>
    </SuppliersWrapper>
  );
};

export default Suppliers;
