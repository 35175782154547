import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n, { i18nInitialized } from "./Languages/INEXT";
import './assets/css/style.scss'
import './assets/css/responsive.scss'

const renderApp = () => {
  const root = createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Router>
          <App />
        </Router>
      </I18nextProvider>
    </React.StrictMode>
  );
};

i18nInitialized.then(renderApp);
