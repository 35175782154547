import styled from "styled-components";

export const TopbarContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110;
  width: 100%;
  padding: 14px 0;
  transition: all 0.5s ease-in-out;
  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-bar-list {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .nav-bar-list a {
    padding: 12px 20px;
    position: relative;
    font-size: 16px;
    font-family: ${(props) =>
    props.lang === "en" ? "var(--medium)" : "var(--arMedium)"};
    font-weight: 300;
    border-radius: 18px;
    transition: all ease-in-out 0.1s;
    text-transform: uppercase;
    &:focus,
    &:hover,
    &.active {
      color: var(--white);
      background: var(--orangeGradient);
      text-decoration: none;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        /* background: linear-gradient(white, white) padding-box,
          linear-gradient(219.06deg, #b959e3 6.88%, #662782 77.05%) border-box; */
        pointer-events: none;
        z-index: -1;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='gradient' x1='1' y1='0' x2='0' y2='0'%3e%3cstop offset='6.88%25' stop-color='%23FFAA06' /%3e%3cstop offset='77.05%25' stop-color='%23D85A1F' /%3e%3c/linearGradient%3e%3c/defs%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='50' stroke='url(%23gradient)' stroke-width='4' stroke-dasharray='6%2c 8' stroke-dashoffset='100' stroke-linecap='round'/%3e%3c/svg%3e");

        border-radius: 18px;
      }
    }
  }
  &.sticky {
    background: var(--lightGradient);
    box-shadow: 0px 3px 10px #001b2ec7;
    z-index: 200;
  }
  .toggleBtn {
    display: none;
  }
  @media (max-width: 1200px) {
    .nav-bar-list a {
      padding: 6px 12px;
    }
  }
`;
