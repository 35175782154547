import React from "react";
import { ProductWrapper } from "./Style";
import { Container, Row } from "react-bootstrap";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  MainHeading,
  SubHeading,
  TextStyled,
} from "../../styled/Elements";
import { ProductBg } from "../../styled/AllImages";
import { productItems } from "../../../data/Data";
import { ContactButton } from "../../styled/CustomElements";
import { useTranslation } from "react-i18next";
import Suppliers from "../Suppliers/Suppliers";

const Products = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const WhatsApp = () => {
    const phoneNumber = "+966556202503";
    const message = "Hello, Super Active Baby  ";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };
  return (
    <ProductWrapper
      id="products"
      style={{ backgroundImage: `url(${ProductBg})` }}
      lang={lang}
    >
      <Container>
        <Row className="common-space">
          <Box className="product-content">
            <MainHeading size="32px" className="blueColor heading-blue products-head" weight="bolder">
              {t("our_products")}
            </MainHeading>
            <MainHeading size="48px" className="orangeColor heading-orange products-head" weight="bolder">
              {t("our_diaper_magic")}
            </MainHeading>
            <TextStyled className="pt-4">{t("our_product_range")}</TextStyled>
          </Box>
        </Row>
        <Row>
          <Box className="product-grid product-main">
            {productItems.map((list, index) => (
              <Box key={index} className="product-container">
                <Box
                  className="product-items"
                  style={{ backgroundImage: `url(${list.bg})` }}
                >
                  {list.img}
                  <HeadingStyled size="24px" weight="300" color="var(--white)">
                    {t(list.heading)}
                  </HeadingStyled>
                  <TextStyled color="var(--white)">{t(list.text)}</TextStyled>
                </Box>
                <ButtonStyled
                  className="product-btn"
                  style={{ boxShadow: `${list.shadow}` }}
                  color={list.color}
                  weight="600"
                >
                  {`${list.btn} ${t('diapers')}`}
                </ButtonStyled>
              </Box>
            ))}
          </Box>
          <Box className="d-flex align-item-center justify-content-center act-prod">
            <ContactButton label={t("contact_us_now")} onClick={WhatsApp} />
          </Box>
        </Row>
      </Container>
      {/* <Suppliers /> */}
    </ProductWrapper>
  );
}

export default Products;
