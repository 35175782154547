import React from "react";
import { ContactUsWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import { Box, MainHeading } from "../../styled/Elements";
import { Message, Phone, WhatsappIcon } from "../../styled/AllImages";
import { ButtonWithIcon } from "../../styled/CustomElements";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const {t} = useTranslation()
  const Map =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.062699793756!2d46.758571010944486!3d24.69037127794914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f06cf7baf3ce9%3A0xddcab026a24890d3!2sAhmad%20Ibn%20Ghalib%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1714735326167!5m2!1sen!2s";

    const WhatsApp = () => {
      const phoneNumber = "+966556202503";
      const message = "Hello, Super Active Baby  ";
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        message
      )}`;
      window.open(whatsappURL, "_blank");
    };
  return (
    <ContactUsWrapper id="contact" className="mt-5">
      <Container>
        <Box className="contact-head text-center">
          <MainHeading className="lightBlue_gradient contact-heading" size="32px" weight="bolder">
            {t('have_questions')}
          </MainHeading>
          <MainHeading className="lightOrange_gradient contact-heading" size="48px" weight="bolder">
            {t('contact_us_today')}
          </MainHeading>
        </Box>
        <Row className="justify-content-center mt-4">
          <Col lg={3} md={4}>
            <ButtonWithIcon
              clName="orangeGradient-bg cntct-btn d-flex gap-2 mt-2"
              label="+966556202503"
              type="tel:+966556202503"
              icon={Phone}
              center
              orange
            />
          </Col>
          <Col lg={3} md={4}>
            <ButtonWithIcon
              clName="orangeGradient-bg cntct-btn d-flex gap-2 mt-2"
              label="+966556202503"
              onClick={WhatsApp}
              icon={WhatsappIcon}
              center
              orange
            />
          </Col>
          <Col lg={3} md={4}>
            <ButtonWithIcon
              clName="orangeGradient-bg d-flex gap-2 mt-2"
              label="info@example.com"
              icon={Message}
              center
              orange
            />
          </Col>
        </Row>
        <Row className="align-items-center pt-4 py-5">
          <Col lg={6} md={12} className="contact-Us">
            <Box className="contect-with-us">
              <ContactForm />
            </Box>
          </Col>
          <Col lg={6} md={12}>
            <Box className="contect-with-you">
              <Box className="contact-box">
                <iframe
                  src={Map}
                  allowfullscreen=""
                  loading="lazy"
                  title="location"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </ContactUsWrapper>
  );
};

export default ContactUs;
