import { BabyFun, BabyShop, Cutes, ForMoms, Hibobi, Huggies, MamaPapa, Product1, Product2, Product3, Product4, Product5, ProductItemBg1, ProductItemBg2, ProductItemBg3, ProductItemBg4, ProductItemBg5 } from "../components/styled/AllImages"
import { LazyImage } from "../components/styled/Elements"

export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'features',
        label: 'Features',
    },
    {
        to: 'products',
        label: 'Products',
    },
    {
        to: 'suppliers',
        label: 'Suppliers',
    },

    {
        to: 'contact',
        label: 'contact_us',
    },
]
export const productItems = [
    {
        img: <LazyImage src={Product1} />,
        bg: ProductItemBg1,
        heading: 'super_active_baby',
        text: 'Small',
        btn: `42`,
        shadow: '0px 6px 14px 1px  rgba(27,169,236,0.5)',
        color: " #0B6F9F",
    },
    {
        img: <LazyImage src={Product2} />,
        bg: ProductItemBg2,
        heading: 'super_active_baby',
        text: 'Medium',
        btn: `36`,
        shadow: '0px 6px 14px 1px rgba(31,138,69,0.5)',
        color: " #0F5928",
    },
    {
        img: <LazyImage src={Product3} />,
        bg: ProductItemBg3,
        heading: 'super_active_baby',
        text: 'Large',
        btn: `32`,
        shadow: '0px 6px 14px 1px rgba(152,70,189,0.5)',
        color: " #5A2B93",
    },
    {
        img: <LazyImage src={Product4} />,
        bg: ProductItemBg4,
        heading: 'super_active_baby',
        text: 'Maxi',
        btn: `30`,
        shadow: '0px 6px 14px 1px  rgba(232,127,33,0.5)',
        color: " #E66021",
    },
    {
        img: <LazyImage src={Product5} />,
        bg: ProductItemBg5,
        heading: 'super_active_baby',
        text: 'XXL',
        btn: `26`,
        shadow: '0px 6px 14px 1px rgba(235,29,36,0.5)',
        color: " #E11C23",
    },
]
export const supplyData = [
    {
        img: <LazyImage src={MamaPapa} />,
    },
    {
        img: <LazyImage src={ForMoms} />,
    },
    {
        img: <LazyImage src={Cutes} />,
    },
    {
        img: <LazyImage src={Huggies} />,
    },
    {
        img: <LazyImage src={BabyFun} />,
    },
    {
        img: <LazyImage src={Hibobi} />,
    },
    {
        img: <LazyImage src={BabyShop} />,
    },
]