import styled from "styled-components";
import { ProductItemBg1 } from "../../styled/AllImages";

export const ProductWrapper = styled.section`
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    backgrund:var(--whiteGradient2);
.product-content{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    text-align: center;
}
.product-grid {
    display: grid;
    width: 100%;
    gap: 15px;
    margin: 50px 0;
    grid-template-columns: repeat(5, 1fr);
    height: auto !important;
}
.products-head {
    line-height: 1;
}
.product-container{ 
    position:relative; 
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:end; 
    margin-bottom: 30px;
    .product-items{
        position: relative;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:end;
        width:210px;
        height:246px;
            padding: 20px;
        border-radius:16px;
        background-position: center;
        background-repeat: repeat;
        background-size: cover;
        & >span{
            width:160px;
            & >img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &>div{
            font-family: ${props => props.lang === 'ar' ? 'var(--regularAr)' : 'var(--logoEn)'};  
        }  
    } 
    .product-btn{
            background: var(--white);
            position: relative;
            bottom: 20px;
            width: 135px;
            border-radius: 10px;
            cursor: default;
            font-family: ${props => props.lang === 'en' ? 'var(--bold)' : "var(--regularAr)"};
            
    }    
    
}
.act-prod {
        margin-top: -40px;
    }
`