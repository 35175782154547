import styled from "styled-components";

export const SuppliersWrapper = styled.section`
.supplier-container{
    text-align: center;
    width: 100%;
    border-radius: 20px;
    background: var(--whiteGradient);
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-shadow: 0px 0px 31px 0px rgba(90,43,147,0.1);
    -moz-box-shadow: 0px 0px 31px 0px rgba(90,43,147,0.1);
    box-shadow: 0px 0px 31px 0px rgba(90,43,147,0.1);
    .supplier-head{
        line-height: 1;
    }
    .suppliers-imgs{
        // width: 100%;
        img{
            width: 100%;
        }
    }
    .supply-grid{
        display: grid;
        width: 100%;
        gap: 15px;
        // margin: 30px 0;
        grid-template-columns: repeat(7, 1fr);
        height: auto !important;
    }
}

`;
