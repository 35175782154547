import { Link } from "react-scroll";
import { Box, LazyImage, SpanStyled } from "../../styled/Elements";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsJustify, BsX } from "react-icons/bs";
import { Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ButtonWithIcon, LogoWrapper } from "../../styled/CustomElements";
import ChangeButton from "../../../Languages/ChangeButton";
import { TopbarContainer } from "./Styled";
import { NavDataConfig } from "../../../data/Data";
import { Headphones, TopbarLogo } from "../../styled/AllImages";

const Topbar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const lang = i18n.language
  const WhatsApp = () => {
    const phoneNumber = "+966556202503";
    const message = "Hello, Super Active Baby  ";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };
  const [isSticky, setIsSticky] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
    const element = document.querySelector(".nav-bar-list");
    element.classList.toggle("showToggle");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <TopbarContainer
      className={`border-bottom-line ${
        isSticky ? "sticky" : ""
      } container-fluid`}
      lang={lang}
    >
      <Container>
        <Row>
          <Box className="nav-bar">
            <LogoWrapper className={`logo`} onClick={() => navigate("/")}>
              <LazyImage src={TopbarLogo} />
            </LogoWrapper>
            <Box className="nav-bar-list">
              {NavDataConfig.map((item, key) => (
                <Box key={key}>
                  <Link
                    to={item.to}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    activeClass="active"
                    onClick={handleToggle}
                  >
                    {t(item.label)}
                  </Link>
                </Box>
              ))}
            </Box>
            <Box className="d-flex align-items-center">
              <ButtonWithIcon
                clName="header-btn gradient-bg d-flex gap-2"
                label="+966556202503"
                onClick={WhatsApp}
                icon={Headphones}
              />
              {/* <ButtonWithIcon clName='header-btn gradient-bg d-flex gap-2' icon={Phone} label='+966556202503' onClick={WhatsApp2} /> */}
              <span className="toggleBtn" onClick={handleToggle}>
                {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
              </span>
            </Box>
          </Box>
        </Row>
      </Container>
      <ChangeButton />
    </TopbarContainer>
  );
};

export default Topbar;
