import React from "react";
import { FeaturesWrapper } from "./Styled";
import { useTranslation } from "react-i18next";

import { Container } from "react-bootstrap";
import {
  Box,
  HeadingStyled,
  LazyImage,
  MainHeading,
  TextStyled,
} from "../../styled/Elements";
import {
  Diaper2,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  FeaturesBg,
} from "../../styled/AllImages";

const Features = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  return (
    <FeaturesWrapper id="features" className="common-space">
      <Box className="features-head position-relative">
        <Container>
          <Box className="text-center">
            <MainHeading size="32px" className="blueColor heading-blue features-heading">
              {t("why_choose")}
            </MainHeading>
            <MainHeading size="48px" className="orangeColor heading-orange features-heading">
              {t("super_active_baby")}{isRTL ? "؟" : "?"}
            </MainHeading>
          </Box>
        </Container>
      </Box>
      <Box
        style={{ backgroundImage: `url(${FeaturesBg})` }}
        className="feature-container"
      >
        <Container>
          <Box className="text-center d-flex justify-content-center pt-4">
            <TextStyled size='18px' className="feature-text">
              {t('your_babys_comfort')}
            </TextStyled>
          </Box>
          <Box className="d-flex justify-content-center w-100 feature-img-container mt-5">
            <Box className="feature-imgs position-relative">
              <LazyImage className="diaper-img" src={Diaper2} />
              <Box className="feature-icons feature1 position-absolute">
                <Box className="feature-img1 position-relative">
                  <LazyImage src={Feature1} />
                </Box>
                <HeadingStyled size='18px' className="text-center feature-name feature-name-one">
                  {t('four_layer')}
                </HeadingStyled>
              </Box>
              <Box className="feature-icons feature2 position-absolute">
                <Box className="feature-img2 position-relative">
                  <LazyImage src={Feature2} />
                </Box>
                <HeadingStyled size='18px' className="text-center feature-name">
                  {t('soft_touch')}
                </HeadingStyled>
              </Box>
              <Box className="feature-icons feature3 position-absolute">
                <Box className="feature-img3 position-relative">
                  <LazyImage src={Feature3} />
                </Box>
                <HeadingStyled size='18px' className="text-center feature-name">
                  {t("breathable_cotton")}
                </HeadingStyled>
              </Box>
              <Box className="feature-icons feature4 position-absolute">
                <Box className="feature-img4 position-relative">
                  <LazyImage src={Feature4} />
                </Box>
                <HeadingStyled size='18px' className="text-center feature-name">
                  {t("frontal_tape")}
                </HeadingStyled>
              </Box>
              <Box className="feature-icons feature5 position-absolute">
                <Box className="feature-img5 position-relative">
                  <LazyImage src={Feature5} />
                </Box>
                <HeadingStyled size='18px' className="text-center feature-name">
                  {t('anti_leakage')}
                </HeadingStyled>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </FeaturesWrapper>
  );
};

export default Features;
