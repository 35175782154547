import styled from "styled-components";

export const FooterWrapper = styled.footer`
padding:12px 0;
background: var(--gradient);
background-repeat: no-repeat;
border-top: 1px solid #ad6fec;
    // background-position: right 5px;
.footer-list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    & a{
        color: var(--white);
        /* font-family: var(--semibold); */
        &:hover{
            // color: var(--text);
        }
    }
    
}
.footer-list a{
    color:var(--white);
    padding-bottom: 8px;
    position: relative;
    font-size:16px;
        font-family: ${props => props.lang === 'en' ? "var(--regular)" : "var(--regularAr)"};
    font-weight: 400;
    cursor: pointer;
}
.footer-revised{
    position:relative;
    padding:25px 25px 25px 25px;
}
.fowl{
    cursor: pointer;
    &:hover{
        color: var(--white);
    }
    
}
.footer-logo{
    width:80px;
}
.f-logo-wrap{
    display:flex;
    align-items:start;
    justify-content:start;
}

`