import ErrorPage from "../components/Common/ErrorPage";
import Index from "./Index";

export const MainRoutes = [
    {
        path: '/',
        element: <Index/>
    },
    {
        path: '*',
        element: <ErrorPage/>
    }
]