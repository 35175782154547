import styled from "styled-components";

export const ContactUsWrapper = styled.div`
  position: relative;
  background: var(--gradient);
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 260px;
    border-radius: 32px 32px 0 0;
    background: var(--gradient);
    top: -260px;
    z-index: -2;
  }
  .contact-heading {
    line-height: .9;
  }
`;
export const ContactFormWrapper = styled.div`
  .field-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 20px 0;
  }
  .input-field {
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: var(--white);
  }

  .input-field::placeholder {
    font-weight: 100;
    font-family: ${(props) =>
    props.lang === "en" ? "var(--regular)" : "var(--regularAr)"};
    color: var(--textPalceholder);
  }
  .error-message {
    font-family: var(--arRegular);
    border: 1px solid #ff00006b;
    color: white;
    padding: 6px 8px;
    border-radius: 8px;
    background-color: #ff00006b;
  }
  .loading-btn {
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-family: var(--regular);
  }
  .contact-social-links {
    position: relative;
    left: 15px;
  }
  .contact-btn svg {
    [fill] {
      fill: var(--white);
    }
    [Stroke] {
      stroke: var(--white);
    }
  }

  // input[type="file"] {
  //     position: relative;
  //     border:1px dashed var(--text);
  //   }

  //   input[type="file"]::file-selector-button {
  //     width: 180px;
  //     color: transparent;

  //   }

  /* Faked label styles and icon */
  input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    top: 19px;
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-repeat: no-repeat;
  }

  input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    top: 19px;
    left: 40px;
    color: var(--textPalceholder);
    content: "Upload Attechment";
  }

  /* ------- From Step 1 ------- */

  /* file upload button */
  input[type="file"]::file-selector-button {
    // border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: none;
    // box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }

  //   /* file upload button hover state */
  //   input[type="file"]::file-selector-button:hover {
  //     background-color: #f3f4f6;
  //   }

  //   /* file upload button active state */
  //   input[type="file"]::file-selector-button:active {
  //     background-color: #e5e7eb;
  //   }

  /* ------------------------ */
`;
